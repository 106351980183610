<template>
  <div>
    <video autoplay playsinline width="100%"></video>
    <canvas style="display:none;"></canvas>
    <mdb-container class="text-center">
      <mdb-btn class="mt-5 p-5" style="font-size: 36px;" size="lg" v-on:click="snap" color="elegant">CAPTURE</mdb-btn>
    </mdb-container>
    <mdb-container class="align-bottom pt-5" style="height: 100%">
      <br /><br /><br />
      <mdb-btn v-on:click="$router.push({ name: 'FlightsList'})" class="mt-5">Quitter</mdb-btn>
    </mdb-container>
  </div>
</template>
<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbBtn,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import ETC from 'easy-ts-camera';

export default {
  name: 'reviewappview',
  data() {
    return {
      dummy: true,
      camera: null,
    };
  },
  components: {
    mdbContainer,
    mdbBtn,
  },
  mounted() {
    this.startcam();
  },
  beforeRouteUpdate() {
    this.camera.stop();
  },
  methods: {
    startcam() {
        const video = document.getElementsByTagName('video')[0];
        const canvas = document.getElementsByTagName('canvas')[0];
        const self = this;

        ETC.initWithUserCamera()
          .streamFrom(video)
          .drawInto(canvas)
          .getCameraAsync()
          .then((camera) => {
            self.camera = camera;
            self.camera.startAsync();
          })
          .catch((error) => {
            console.log(error);
            // Mostly happens if the user blocks the camera or the media devices are not supported
          });
    },
    snap() {
      this.pushtoserver(this.camera.snapAsDataUrl());
      this.camera.startAsync();
    },
    pushtoserver(base64dataurl) {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/review/capture-img', { flightid: this.$route.params.id, imgdata: base64dataurl });
    },
  },
};
</script>
